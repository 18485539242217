/* ul, li {
  margin: 0;
  padding: 0;
  list-style: none; 
} */

h1, h2, h3, h4, h5, h6 {

  font-size: inherit;
  margin: 0;
}

body {
  font-family: 'Interstate', sans-serif;
  margin: 0;
  padding: 0;

}


.menu-header {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  background-color: #2e2e38;
  padding: 20px  30px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px; 
  margin-right: 10px;
}

.slogan {
  color: #fff;
  font-size: 14px;
}

.menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.menu li {
  margin-left: 20px;
}

.menu a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

.menu a:hover {
  color: #ffd700; 
}

/* MainPage */

.main-container {
  position: relative;
  background-image: url('./images/main_bg.jpg'); 
  background-size: cover;
  background-position: center;
  height: 60vh; 
}

.overlay {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0; 
  width: 100%; 
  height: 80%;
  background: linear-gradient(180deg, rgba(46, 46, 56, 0) 0%, #2e2e38 100%);
  z-index: 110;
  pointer-events: none;
}

.text-content {
  position: absolute;
  bottom: 100px;
  left: 100px;
  z-index: 120;
  color: white;
}

.text-content h3 {
  color: #ffe600; 
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 10px;
}

.text-content h1 {
  font-size: 48px;
  line-height: 60px;
  font-weight: 300;
  max-width: 800px;
  margin: 0 auto;
}

.business-leaders-section {
  background-color: #2e2e38; 
  color: white;
  padding: 50px 0px;
}

.content-wrapper {
padding: 0px 100px;
}

.section-flex {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.section-title {
  color: #ffe600;
  font-size: 48px;
  font-weight: 300;
  line-height: 60px;
  margin-bottom: 20px;
}

.business-leaders-section p {
  font-size: 24px;
  line-height: 30px;
  font-weight: 300;
  margin-bottom: 20px;
}

.diversity-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: #fff;
  padding: 50px 100px;
}

.diversity-block {
  display: flex;
  gap: 20px;
  align-items: center;
}

.diversity-block.reverse-layout {
  flex-direction: row-reverse; 
}

.diversity-image {
  width: 50%;
  height: auto;
  object-fit: cover;
}

.text-diversity {
  max-width: 500px;
  padding: 0 8.33333%;


}

.text-diversity h3 {
  color: #2e2e38;
  font-size: 40px;
  font-weight: 300;
  line-height: 60px;

}

.text-diversity p {
  color: #2e2e38;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
}

/* Main nominations section */
.nominations-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2e2e38;
  padding: 50px 100px;
}

.nominations-content {
  display: flex;
  flex-direction: row;
  background-color: #2e2e38;
  align-items: flex-start;
  gap: 50px;
  width: 100%;
}

.nominations-list {
  width: 50%;
  color: white;
  /* padding: 0 148px; */
}

.nominations-list h2 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 300;
  margin-bottom: 20px;
}

.nominations-list ul {
  list-style: none;
  padding: 0;
}

.nominations-list li {
  margin-bottom: 15px;
  line-height: 22px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #747480;
  padding-bottom: 10px;
}

.nominations-list p {
  font-size: 18px;
  color: #ddd;
  font-weight: 300;
}

.nomination-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.nominations-arrow-icon {
  width: 24px;
  height: 24px;
  fill: white;
  transition: transform 0.8s ease;
}

.nominations-list li.expanded .nominations-arrow-icon {
  transform: rotate(90deg); 
}

.nominations-expanded-text {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.8s ease, opacity 0.8s ease; /* Slower expansion */
}

.nominations-list li.expanded .nominations-expanded-text {
  max-height: 200px; 
  opacity: 1;
}

/* Image section */
.nominations-image-section {
  width: 50%;
  position: relative;
}

.nominations-image-section img {
  width: 100%;
  height: auto;
}

.nominations-gradient-overlay {
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, transparent 0%, black 100%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.quote-section {
  position: relative;
  background: url('./images/quoteBackground.jpg') no-repeat center center;
  background-size: cover;
  min-height: 60vh; /* Изменено на min-height для лучшей адаптивности */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto; /* Установите авто отступы для центрирования */
  padding: 20px;

}

.quote-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 20%, transparent 80%), 
              linear-gradient(96.81deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), 
              linear-gradient(254.73deg, rgba(0, 0, 0, 0.1) 30%, transparent 70%);
}

.quote-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 20px;
  color: white;
  max-width: 70%;
  width: 100%;
  box-sizing: border-box;
}


.quote-title {
  font-size: 120px;
  line-height: normal;
  content: "“";
  color: #ffe600;
  font-family: Georgia, serif;
  font-weight: 400;
  position: absolute;
  top: 20px;
}

.quote-content p {
  margin-top: 60px;
  margin-bottom: 20px;
  font-size: 32px;
  line-height: 44px;
  font-family: Georgia, serif;
  font-weight: 400;
 
}

.quote-content .author {
  font-size: 16px;
    font-family: 'Interstate', sans-serif;
  line-height: 22px;
  font-weight: 700;
  margin: 0;
}

.white {
  color: #fff;
}

.pb-20 {
  padding-bottom: 20px;
}

.yellow {
  color: #ffe600;
}

.award-info-section {
  background-color: #2e2e38;
  color: white;
  padding: 50px 100px;
  font-family: 'Interstate', sans-serif;
  position: relative;
}

.award-info-section h2 {
  font-size: 48px;
  line-height: 60px;
  color: #ffe600;
  font-weight: 300;
  margin-bottom: 20px;
}

.award-info-section p {
  font-size: 22px;
  line-height: 30px;
  font-weight: 300;
}

.award-info-section h3 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  
}

.award-info-list {
  color: white;
  font-size: 22px;
  font-weight: 300;
  line-height: 28px;
  padding-left: 30px;
}

.award-info-list li {
  padding-bottom: 5px;
}

.expanded-text {
  margin-top: 20px;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.toggle-button {
  background-color: transparent;
  border: none;
  font-weight: 700;
  line-height: 1.75;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 0;
}

.toggle-button:hover {
  text-decoration: underline;
}

.collapsed-gradient {
  position: relative;
  max-height: 250px; 
  overflow: hidden;
}

.collapsed-gradient::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(46, 46, 56, 0) 50%, #2e2e38 100%);
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
}

.full-text {
  max-height: none;
  overflow: visible;
}

.full-text::after {
  content: none; 
}


.criteria-info-section {
  background-color: #ffe600;
  color: #2c2c38;
  padding: 50px 100px;
  font-family: 'Interstate', sans-serif;
  position: relative;
}

.criteria-info-section h2 {
  font-size: 48px;
  line-height: 60px;
  color: #2c2c38;
  font-weight: 300;
  margin-bottom: 20px;
}

.criteria-info-section p {
  font-size: 22px;
  line-height: 30px;
  font-weight: 300;
}

.criteria-info-section h3 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  
}

.criteria-info-list {
  color: #2c2c38;
  font-size: 22px;
  font-weight: 300;
  line-height: 28px;
  padding-left: 30px;
}

.criteria-info-list li {
  padding-bottom: 5px;
}


.criteria-collapsed-gradient {
  position: relative;
  max-height: 250px; 
  overflow: hidden;
}

.criteria-collapsed-gradient::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(46, 46, 56, 0) 50%, #ffe600 100%);
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
}

.criteria-full-text {
  max-height: none;
  overflow: visible;
}

.criteria-full-text::after {
  content: none; 
}

.criteria-toggle-button {
  background-color: transparent;
  border: none;
  font-weight: 700;
  line-height: 1.75;
  color: #2c2c38;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 0;
}

.criteria-toggle-button:hover {
  text-decoration: underline;
}

.judges-container {
  position: relative;
  background-image: url('./images/judges_bg.jpg'); 
  background-size: cover;
  background-position: center;
  height: 60vh; 
}

.judges-section {
  padding: 0px 100px 50px 100px;
}



.year-tabs {
  display: flex;
  justify-content: start;
  margin-bottom: 20px;
  gap: 20px;
}

.year-tab {
  background-color: #2c2c38;
  border: none;
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.year-tab.active {
  background-color: #ffe600;
  color: black;
}

.year-tab:hover {
  background-color: #ffe600;
  color: black;
}

.judges-row {
  display: flex;
  justify-content: start; 
  flex-wrap: wrap;
  gap: 50px;
}

.judges-section h2{
  font-size: 36px;
  color: #2e2e38;
  margin-bottom: 20px;
  font-weight: 400;
}

.judge-card {
  background-color: #2e2e38;
  color: white;
  width: 30%;

  text-align: start;
}

.judge-photo {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.judge-info {
  padding: 20px 20px 30px 20px;
}

.judge-info h4 {
  
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 24px;
}

.judge-info p {
  font-size: 16px;
  line-height: 24px;
}

.strategy-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* gap: 50px; */
  padding: 50px 100px;
  background-color: #2e2e38;
}

.strategy-card {
  background-color: #2e2e38; 
  color: white;
  padding: 135px 180px 50px 118px;
}

.border-r {
  border-right: 1px solid #c4c4cd;
}

.strategy-card h3 {
  font-size: 32px;
  color: #ffe600;
  line-height: 40px;
  font-weight: 300;
  margin-bottom: 20px;
}

.strategy-card p {
  font-size: 20px;
  line-height: 28px;
}

/* Unique styles for EY Business Academy section */
.ey-business-academy-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 100px;
  background-color: #fff;
}

.ey-text-content {
  flex: 1;
  padding-right: 50px;
}

.ey-text-content h2 {
  font-size: 36px;
  color: #2e2e38;
  margin-bottom: 20px;
  font-weight: 400;
}

.ey-text-content p {
  font-size: 20px;
  color: #2e2e38;
  margin-bottom: 30px;
}

.ey-learn-more-btn {
  background-color: transparent;
  border: 2px solid #2e2e38;
  padding: 12px 25px;
  color: #2e2e38;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-transform: uppercase;
}

.ey-learn-more-btn:hover {
  background-color: #2e2e38;
  color: white;
}

.ey-list {
  color: #2c2c38;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
  margin: 10px 0px;
  padding-left: 30px;
}

.ey-list li {
  padding-bottom: 5px;
}

.ey-image-content {
  flex: 1;
}

.ey-image-content img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.partners-section {
  padding: 50px 100px;
  background-color: #fff; 
  color: white;
  text-align: center;
}

.partners-section h2 {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 20px;
  color: #2c2c38; 
  line-height: 60px;
}

.partners-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
  margin-bottom: 20px;
}

.partners-grid img {
  width: 200px;
  height: auto;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.partners-grid img:hover {
  transform: scale(1.1);
}

.winners-container {
  position: relative;
  background-image: url('./images/winners_bg.webp'); 
  background-size: cover;
  background-position: center;
  height: 60vh; 
}

/* Minimalist styling for the Winners Page */
.winners-section {
  background-color: #2e2e38;  /* Softer background color */
  color: #2e2e38;
  padding: 50px 100px;
}

.winners-wrapper {

  background-color: #fff;
  padding: 30px 50px;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.05);  
}


.winners-year {
  margin-bottom: 30px;
}

.winners-year h2 {
  font-size: 32px;
  color: #ffe600;
  font-weight: 500;
  margin-bottom: 15px;
  border-bottom: 1px solid #ffe600;  /* Thinner bottom border */
  padding-bottom: 8px;
}

.winners-year ul {
  list-style-type: none;
  padding-left: 0;
}

.winners-year li {
  font-size: 22px;
  color: #4a4a4a;
  line-height: 28px;  
  padding: 12px 0;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s ease;
}

.winners-year li strong {
  font-weight: 600;
  color: #2e2e38;
}




.gallery-container {
  position: relative;
  background-image: url('./images/gallery_bg.webp'); 
  background-size: cover;
  background-position: center;
  height: 60vh; 
}

.gallery-section {
  padding: 50px 100px;
  background-color: #2e2e38; 
  color: #2c2c38;
  text-align: center;
}

.gallery-section h2 {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 20px;
  line-height: 60px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 items per row */
  gap: 20px;
  justify-items: center; /* Center images within the grid items */
}

.gallery-item img {
  width: 100%;

  height: 400px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gallery-item img:hover {
  transform: scale(1.05); 
}

/* For screens smaller than 1200px, 3 items per row */
@media (max-width: 1200px) {
  .gallery-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* For screens smaller than 900px, 2 items per row */
@media (max-width: 900px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* For screens smaller than 600px, 1 item per row */
@media (max-width: 600px) {
  .gallery-grid {
    grid-template-columns: 1fr;
  }

  .gallery-item img {
    height: auto; /* Adjust height to maintain aspect ratio */
  }
}


.contact-us-section {
  padding: 50px 100px;
  text-align: center;
  background-color: #2e2e38;
  color: white;
}

.contact-us-section h2 {
  color: #ffe600;
  font-size: 32px;
  line-height: 40px;
  font-weight: 300;
  margin-bottom: 30px;
}

.contact-us-section p {
  color: white;
  font-weight: 300;
  line-height: 1.75;
  font-size: 16px;
  margin-bottom: 20px;
}

.contact-mail-link {
  border: 1px solid #c4c4cd;
  border-radius: 50%;
  color: inherit;
  display: inline-block;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: transform 0.3s ease, border-color 0.3s ease;
}

.contact-mail-link-flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-mail-link:hover {
  border: 1px solid #ffe600;
  transform: scale(1.1);
}

.contact-mail-link img {
  width: 20px;
  height: 20px;
}


.contact-section {
  display: flex;
  justify-content: space-between;
  padding: 50px 100px;
  background-color: #2e2e38; 
  height: 100vh;
}

.contact-form-container,
.contact-info-container {
  width: 48%;
}

.contact-form-container h2,
.contact-info-container h2 {
  font-size: 40px;
  line-height: 50px;
  color: #fff; 
  margin-bottom: 40px;
  font-weight: 300;
}

.contact-form-container form {
  display: flex;
  flex-direction: column;
}

.contact-form-container label {
  color: #fff;
  margin-bottom: 5px;
  font-size: 18px;
}

.contact-form-container input,
.contact-form-container textarea {
  background-color: #1a1a24;
  color: #fff;
  margin-bottom: 40px;
  padding: 20px;
  color: #fff;
  border: 1px solid #1a1a24;
}

.contact-form-container input::placeholder,
.contact-form-container textarea::placeholder {
  color: #fff;
  font-size: 16px;
  font-family: 'Interstate', sans-serif;
  font-weight: 300;
}

.contact-form-container textarea {
  height: 100px;
}

.captcha-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.captcha-container p {
  color: #fff;
  margin-right: 10px;
}

.contact-form-container .submit-btn {
  background: none;
    border: 1px solid white;
    color: white;
    padding: 15px 27px;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.contact-form-container .submit-btn:hover {
  background-color: #fff;
  color: #000;
}

.success-message {
  color: #0f0;
  font-size: 16px;
  margin-top: 15px;
}

.contact-info-container p {
  color: #fff;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 1.6;
}

.contact-info-container a {
  font-weight: bold;
  color: #ffe600;
}

.footer-section {
  background-color: #fff;
  padding: 30px 100px;
  border-top: 1px solid #ddd;
  font-family: 'Interstate', sans-serif;
}

.footer-container {
  display: flex;
  justify-content: start;
  gap: 200px;
  align-items: center;
}

.footer-logo img {
  height: 60px;
}

.footer-slogan {
  font-size: 12px;
  color: #2e2e38;
  margin-top: 10px;
}

.footer-links ul {
  display: flex;
  list-style: none;
  gap: 30px;
  padding: 0;
}

.footer-links ul li {
  font-size: 16px;
}

.footer-links ul li a {
  color: #2e2e38;
  text-decoration: none;
  font-weight: 700;
}

.footer-links ul li a:hover {
  text-decoration: underline;
}

.footer-social {
  display: flex;
  gap: 10px;
}

.footer-social a {
  display: inline-block;
  border: 1px solid #c4c4cd;
  border-radius: 50%;
  color: inherit;
  width: 44px;
  height: 44px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.footer-social a:hover {
  border: 1px solid #ffe600;
  transform: scale(1.1);
}

.footer-social img {
  width: 15px;
  height: 15px;
}

.footer-flex {
  display: flex;
  justify-content: space-between;
}

.footer-legal {
  margin-top: 20px;
  font-size: 14px;
  color: #747480;
  line-height: 1.43;
  text-align: start;
  display: flex;
  justify-content: space-between;
}

.footer-legal p {
  max-width: 700px;
}

.footer-legal strong {
  font-weight: bold;
}
